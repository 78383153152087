const nextSteps = {

	elem: {
		$more: $('.next-steps__item'),		
		$toggle: $('.next-steps__toggle'),
		$list: $('.next-steps__list')
	},

	init() {
		if (this.elem.$more.length > 5) {
			this.initMore();
		}
	},

	initMore(){

		/* Create a div and move the button into it */
		this.elem.$list.append('<div id="toggle_wrapper" class="closed"></div>');
		$('#next-steps__toggle').detach().appendTo('#toggle_wrapper');

		nextSteps.elem.$toggle.css({opacity: 1});
		const lWrapper = $('#toggle_wrapper');
		const lItems = [];

		for (let i = this.elem.$more.length - 1; i >= 5; i = (i-1)) {
			let lItem = this.elem.$more[i];
			lItems.push(lItem);
			$(lItem).slideUp(function(){
				$(this).css({opacity: 1});
			});
		}

		nextSteps.elem.$toggle.on('click', function(e) {
			e.preventDefault();

			let lExpanded;
			$(e.target).attr({
				'aria-expanded': function (i, txt) {
					lExpanded = (txt === 'false') ? 'true' : 'false';
					return lExpanded;
				},
			});
			$(e.target).find('.text').html(
				function (i, txt) {
					return (txt === 'See More Steps') ? txt.replace('More', 'Fewer') : txt.replace('Fewer', 'More');
				}
			);
			
			if(lExpanded === 'true'){
				$(lItems).slideDown(function(){
					$(this).attr('aria-hidden', 'false');
				});
			}else{
				$(lItems).slideUp(function(){
					$(this).attr('aria-hidden', 'true');
				});
			}

			lWrapper.toggleClass('closed')
		});
	}
};

export { nextSteps };
