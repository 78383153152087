import { ready } from 'brei-project-utils';

import { forms } from './modules/forms';
import { video } from './modules/video';
import { media } from './modules/media';
import { nextSteps } from './modules/next-steps';

const main = {

	elem: {
	},

	init() {

		forms.init();
		video.init();
		media.init();
		nextSteps.init();		

	}

};

ready(function () {
	main.init();
});
